<h1 mat-dialog-title>Create a new folder</h1>

<mat-dialog-content>
  <mat-form-field class="example-full-width">
    <input matInput placeholder="Folder Name" [(ngModel)]="folderName">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button [mat-dialog-close]="folderName" color="primary">OK</button>
</mat-dialog-actions>